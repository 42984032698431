import React from 'react'
import Navigation from '../../components/navigation';

import Uchenna from "../../images/members/uchenna.svg";
import Uwazie from "../../images/members/uwazie.svg";
import Nwele from "../../images/members/nwele.svg";
import Okeke from "../../images/members/okeke.svg";
import Onwi from "../../images/members/onwi.svg";
import Uhuegbu from "../../images/members/uhuegbu.svg";
import Nwadike from "../../images/members/nwadike.svg";
import Echeme from "../../images/members/echeme.svg";


export default function index() {

    const key_members = [
      { name: "Mazi Echeme Ibeawuchi", position: "Chairman", image:  Echeme},
      { name: "Mazi Ogbuehu Uchenna", position: "Welfare Chairman", image: Uchenna },
      { name: "Mazi Emenike Ndubuisi", position: "Secretary", image: "" },
      { name: "Mazi Okeke Nnadozie", position: "FinSec/Treasurer", image: Okeke },
      { name: "Mazi Osuji Ebere", position: "P.R.O", image: "" },
      { name: "Mazi Ibezim Nwankwo", position: "Provost", image: "" },
    ];

    const members = [
      { name: "Mazi Dibiaocha Jude", image: "" },
      { name: "Mazi Ogundu Obinna", image: "" },
      { name: "Mazi Chukwunyere Chiki", image: "" },
      { name: "Mazi Uhie Uchenna", image: "" },
      { name: "Mazi Nwakanma Francis", image: "" },
      { name: "Mazi Nwadike Chibuzo", image: Nwadike },
      { name: "Mazi Agbaeru Chika", image: "" },
      { name: "Mazi Uzoukwu Chinedu", image: "" },
      { name: "Mazi Uwazie Edwin", image: Uwazie },
      { name: "Mazi Izuogu John", image: "" },
      { name: "Mazi Agwuocha Obughike", image: "" },
      { name: "Mazi Mgborogwu Okechukwu", image: "" },
      { name: "Mazi Chukwuocha Ifeanyi", image: "" },
      { name: "Mazi Nwagwu Paul", image: "" },
      { name: "Mazi Nwankwuelu Udoka", image: "" },
      { name: "Mazi Oforegbu Cosmos", image: "" },
      { name: "Mazi Okafor Chima Marshall", image: "" },
      { name: "Mazi Nwele Iyke", image: Nwele },
      { name: "Mazi Nwankwo Nchekwube", image: "" },
      { name: "Mazi Onwi Uchechi", image: Onwi },
      { name: "Mazi Uhuegbu Chinemerem", image: Uhuegbu },
      { name: "Mazi Onuoha Joseph", image: "" },
      { name: "Mazi Mbagwo Ifeanyi", image: "" },
      { name: "Mazi Okonkwo Chinedu", image: "" },
      { name: "Mazi Nwankwo Chinedu", image: "" },
      { name: "Mazi Nwokenna Chikezie", image: "" },
      { name: "Mazi Chinedu Osuagwu", image: "" },
      { name: "Mazi Ohanele Chikodi", image: "" },
      { name: "Mazi Epegba Maduabuchi", image: "" },
      { name: "Mazi Okafor Chima", image: "" },
      { name: "Mazi Ajelu Ugochukw", image: "" },
      { name: "Mazi Ndukwe Ndubuisi", image: "" },
      { name: "Mazi Nwankonye Kizito", image: "" },
      { name: "Mazi Okafor Paul", image: "" },
      { name: "Mazi Chukwuma Ben", image: "" },
      { name: "Mazi Korie Emmanuel", image: "" },
      { name: "Mazi Anukwem Obinna", image: "" },
      { name: "Mazi Ehumadu Uzoma", image: "" },
      { name: "Mazi Otumba Nnaemeka", image: "" },
      { name: "Mazi Nwolu Chikodi", image: "" },
      { name: "Mazi Uwajumaogu Chinenye", image: "" },
      { name: "Mazi Ucheoma the order", image: "" },
      { name: "Mazi Odim Anebom", image: "" },
      { name: "Mazi Onyeocha China", image: "" },
      { name: "Mazi Ejeh Chukwudi", image: "" },
      { name: "Mazi Nwokenna Eliezer", image: "" },
      { name: "Mazi Ejeh Maduabuchi", image: "" },
      { name: "Mazi Utah Donatus", image: "" },
      { name: "Mazi Ukaegbu Ekene", image: "" },
      { name: "Mazi Onyemaobi Uchechukwu", image: "" },
      { name: "Mazi Agu kelechi", image: "" },
    ];
    return (
      <div>
        <Navigation pageTitle="Our Members" />
        <div className="flex mt-6">
          <h1 className="font-bold sm:text-5xl mx-auto">Our Members</h1>
        </div>

        {/* team section */}
        {/* each image must be rounded full and description be text centred */}
        <div className="flex flex-wrap justify-center">
          {key_members.map((member, index) => (
            <div
              className="flex flex-col justify-center items-center m-4"
              key={index}
            >
              <div className="flex flex-col justify-center items-center">
                {member.image === "" ? (
                  <div className="flex justify-center items-center w-20 h-20 p-1 rounded-full bg-gray-300 ring-2 ring-gray-300 dark:ring-gray-500 mb-3" />
                ) : (
                  <img
                    src={member.image}
                    className="flex justify-center items-center w-20 h-20 p-1 rounded-full mb-3"
                    alt="Member"
                  />
                )}
                <h1 className="text-center font-bold text-">{member.name}</h1>
                <p className="text-center">{member.position}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-wrap justify-center">
          {members.map((member, index) => (
            <div
              className="flex flex-col justify-center items-center m-4"
              key={index}
            >
              <div className="flex flex-col justify-center items-center">
                {member.image === "" ? (
                  <div className="flex justify-center items-center w-16 h-16 p-1 rounded-full bg-gray-300 ring-2 ring-gray-300 dark:ring-gray-500 mb-3" />
                ) : (
                  <img
                    src={member.image}
                    className="w-16 h-16 rounded-full"
                    alt=""
                  />
                )}
                <h1 className="text-center font-bold text-">{member.name}</h1>
                <p className="text-center">Member</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}
